///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// These functions are named for the forms on which they appear
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

function dspProgramShifts(selectID) {
	console.warn("QUIT USING THE CALLBACKS FILE. IMPLEMENT IN EACH SECTION DIRECTLY");
	var records = getRecordSet("user",DUI_DataReturned);
	var dd = document.getElementById("userid");
	updateDropdown(records,dd,selectID,"UserID","ReverseName");
}

function formUserCategory(selectID) {
	console.warn("QUIT USING THE CALLBACKS FILE. IMPLEMENT IN EACH SECTION DIRECTLY");
	var records = getRecordSet("usercategory",DUI_DataReturned);
	var dd = document.getElementById("parentusercategoryid");
	updateDropdown(records,dd,selectID,"UserCategoryID","Name");
}

function formEventCategory(selectID) {
	var records = getRecordSet("eventcategory",DUI_DataReturned);
	var dd = document.getElementById("parenteventcategoryid");
	updateDropdown(records,dd,selectID,"EventCategoryID","Name");
}

function formClassCategory(selectID) {
	console.warn("QUIT USING THE CALLBACKS FILE. IMPLEMENT IN EACH SECTION DIRECTLY");
	var records = getRecordSet("classcategory",DUI_DataReturned);
	var dd = document.getElementById("parentclasscategoryid");
	updateDropdown(records,dd,selectID,"ClassCategoryID","Name");
}

function formBilling(selectID) {
	console.warn("QUIT USING THE CALLBACKS FILE. IMPLEMENT IN EACH SECTION DIRECTLY");

	var records = getRecordSet("agency",DUI_DataReturned);
	var frm = document.getElementById("mainform");

	// Billed To
	frm.bprefix.value = '';
	frm.bfirstname.value = '';
	frm.bmiddleinitial.value = '';
	frm.blastname.value = '';
	if(records[0].getElementsByTagName("Name")[0].firstChild)
		frm.bcompanyname.value = records[0].getElementsByTagName("Name")[0].firstChild.nodeValue;
	else
		frm.bcompanyname.value = '';
	if(records[0].getElementsByTagName("Address1")[0].firstChild)
		frm.baddress1.value = records[0].getElementsByTagName("Address1")[0].firstChild.nodeValue;
	else
		frm.baddress1.value = '';
	if(records[0].getElementsByTagName("Address2")[0].firstChild)
		frm.baddress2.value = records[0].getElementsByTagName("Address2")[0].firstChild.nodeValue;
	else
		frm.baddress2.value = '';
	if(records[0].getElementsByTagName("City")[0].firstChild)
		frm.bcity.value = records[0].getElementsByTagName("City")[0].firstChild.nodeValue;
	else
		frm.bcity.value = '';
	if(records[0].getElementsByTagName("StateCode")[0].firstChild)
		frm.bstatecode.value = records[0].getElementsByTagName("StateCode")[0].firstChild.nodeValue;
	else
		frm.bstatecode.value = '';
	if(records[0].getElementsByTagName("PostalCode")[0].firstChild)
		frm.bpostalcode.value = records[0].getElementsByTagName("PostalCode")[0].firstChild.nodeValue;
	else
		frm.bpostalcode.value = '';
	if(records[0].getElementsByTagName("CountryCode")[0].firstChild &&
	   records[0].getElementsByTagName("CountryCode")[0].firstChild != '')
		for(var i=0; i<frm.bcountrycode.options.length; i++) {
			if(frm.bcountrycode.options[i].value == records[0].getElementsByTagName("CountryCode")[0].firstChild.nodeValue)
				frm.bcountrycode.selectedIndex = i;
		}
	else
		for(var i=0; i<frm.bcountrycode.options.length; i++) {
			if(frm.bcountrycode.options[i].value == 'US')
				frm.bcountrycode.selectedIndex = i;
		}
	if(records[0].getElementsByTagName("Phone")[0].firstChild)
		frm.bphone.value = records[0].getElementsByTagName("Phone")[0].firstChild.nodeValue;
	else
		frm.bphone.value = '';
	frm.bextension.value = '';
	if(records[0].getElementsByTagName("Email")[0].firstChild)
		frm.bemail.value = records[0].getElementsByTagName("Email")[0].firstChild.nodeValue;
	else
		frm.bemail.value = '';

	// Invoiced To
	frm.iprefix.value = '';
	frm.ifirstname.value = '';
	frm.imiddleinitial.value = '';
	frm.ilastname.value = '';
	if(records[0].getElementsByTagName("Name")[0].firstChild)
		frm.icompanyname.value = records[0].getElementsByTagName("Name")[0].firstChild.nodeValue;
	else
		frm.icompanyname.value = '';
	if(records[0].getElementsByTagName("Address1")[0].firstChild)
		frm.iaddress1.value = records[0].getElementsByTagName("Address1")[0].firstChild.nodeValue;
	else
		frm.iaddress1.value = '';
	if(records[0].getElementsByTagName("Address2")[0].firstChild)
		frm.iaddress2.value = records[0].getElementsByTagName("Address2")[0].firstChild.nodeValue;
	else
		frm.iaddress2.value = '';
	if(records[0].getElementsByTagName("City")[0].firstChild)
		frm.icity.value = records[0].getElementsByTagName("City")[0].firstChild.nodeValue;
	else
		frm.icity.value = '';
	if(records[0].getElementsByTagName("StateCode")[0].firstChild)
		frm.istatecode.value = records[0].getElementsByTagName("StateCode")[0].firstChild.nodeValue;
	else
		frm.istatecode.value = '';
	if(records[0].getElementsByTagName("PostalCode")[0].firstChild)
		frm.ipostalcode.value = records[0].getElementsByTagName("PostalCode")[0].firstChild.nodeValue;
	else
		frm.ipostalcode.value = '';
	if(records[0].getElementsByTagName("CountryCode")[0].firstChild &&
	   records[0].getElementsByTagName("CountryCode")[0].firstChild != '')
		for(var i=0; i<frm.icountrycode.options.length; i++) {
			if(frm.icountrycode.options[i].value == records[0].getElementsByTagName("CountryCode")[0].firstChild.nodeValue)
				frm.icountrycode.selectedIndex = i;
		}
	else
		for(var i=0; i<frm.icountrycode.options.length; i++) {
			if(frm.icountrycode.options[i].value == 'US')
				frm.icountrycode.selectedIndex = i;
		}
	if(records[0].getElementsByTagName("Phone")[0].firstChild)
		frm.iphone.value = records[0].getElementsByTagName("Phone")[0].firstChild.nodeValue;
	else
		frm.iphone.value = '';
	frm.iextension.value = '';
	if(records[0].getElementsByTagName("Email")[0].firstChild)
		frm.iemail.value = records[0].getElementsByTagName("Email")[0].firstChild.nodeValue;
	else
		frm.iemail.value = '';
}
