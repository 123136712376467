/**
 * TableDrag Class
 * ----------------------------------------
 * Draggable table rows. Creates a new table inside an absolutely positioned div and reinserts row based on floated position.
 *
 * Kyle Perkins <kyle@globalreach.com>
 *
 *
 * Example:
 *
 * <table>
 * 		<tr id="click1"><td>Hello</td></tr>
 *		<tr id="click2"><td>World</td></tr>
 * </table>
 *
 * var dragObject = new TableDrag(true, false);
 * dragObject.setDraggables('click1', 'click2');
 * dragObject.bindDrop(function(beginLocation, endLocation, indexName){alert('An object ('+indexName+') has been moved from '+beginLocation+' to '+endLocation););
 *
 */

// Use Prototype's Class creator
var TableDrag = function () {};


TableDrag.prototype = {

	/**
	 * Init/Instantiate
	 *
	 * showShadow - bool: Show shadow box, where the row will be dropped.
	 * showBase - bool: Show base box, where the row originated.
	 *
	 * returns nothing
	 */
	initialize: function( showShadow, showBase)
	{
		// This document
		this.doc = document;

		// The floated div
		this.divObj = '';

		// The row inserted in the float
		this.objRow = '';

		this.rowObjPos = '';
		this.bodyObj = document.getElementsByTagName('body')[0];
		this.objRows = '';
		this.objRRow = '';
		this.objPosY = '';
		this.objPosX = '';
		this.grabPosY = '';
		this.grabPosX = '';
		this.rowObjClone = '';
		this.draggables = '';

		this.nodragRight = 0;
		this.nodragLeft = 0;

		this.dragFlag = true;

		// Event caches
		this.moveBoxCache = '';
		this.dropBoxCache = '';

		this.currentID = 0;
		this.currentCount = 0;
		this.endCount = 0;

		if (showShadow != null)
			this.showShadow = showShadow;
		else
			this.showShadow = true;

		if (showBase != null)
			this.showBase = showBase;
		else
			this.showBase = false;

		this.shadowSet = false;
		this.shadowRow = '';

		this.bindDropFunction = function(){};
		this.bindMoveFunction = function(){};
	},


	/**
	 * Return object position
	 *
	 * rowObject - object: The object of which to get the position.
	 *
	 * returns Array[left offset, top offset]
	 */
	objectPosition: function(rowObject)
	{
		// Grab the offsets within the parent
		objLeft = rowObject.offsetLeft;
		objTop = rowObject.offsetTop;

		// Grab offsets of the parents until no parent exists (the DOM root)
		while(rowObject.offsetParent != null)
		{
			objParent = rowObject.offsetParent;
			objLeft += objParent.offsetLeft;
			objTop += objParent.offsetTop;
			rowObject = objParent;
		}
		return [objLeft, objTop];
	},

	/**
	 * Set draggable rows.
	 *
	 * arguments - string: IDs of draggable rows
	 *
	 * returns nothing
	 */
	setDraggables: function()
	{
		for (var i = 0; i < arguments.length; i++)
		{
			if ($(arguments[i]))
			{
				Event.observe(arguments[i], 'mousedown', this.dragRow.bindAsEventListener(this, $(arguments[i])));
				$(arguments[i]).style.cursor = 'pointer';
				this.draggables[i] = arguments[i];
			}
		}
	},

	/**
	 * Set draggable boundaries.
	 *
	 * dragLeft - integer: pixels to disable dragging from the left
	 * dragRight - integer: pixels to disable dragging from the right
	 *
	 * returns nothing
	 */
	setNoDrag: function(dragLeft, dragRight)
	{
		this.nodragLeft = dragLeft;
		this.nodragRight = dragRight;
	},

	/**
	 * Event-triggered function, starts the drag process.
	 *
	 * thisEvent - event object: event that triggered the drag
	 * rowObj - row object: row to be dragged
	 *
	 * returns nothing
	 */
	dragRow: function(thisEvent, rowObj)
	{
		if (this.dragFlag)
		{
			//Disable text selection
			document.onselectstart=function(){return false;}
			if (window.sidebar)
				document.onmousedown=function(){return false;}

			if(!thisEvent)
				var thisEvent=window.event;

			// Get position of the event
			if (document.all){ this.grabPosY = thisEvent.clientY }
				else { this.grabPosY = thisEvent.pageY; }
			if (document.all){ this.grabPosX = thisEvent.clientX; }
				else { this.grabPosX = thisEvent.pageX; }

			// Store the object in the class for a bit easier handling
			// Update by lisa: draggable is now an icon, so need to get the row
			// the icon is in instead of the row directly
			rowObj = rowObj.parentNode.parentNode;
			this.objRow = rowObj;

			// Get row position
			this.rowObjPos = this.objectPosition(this.objRow);

			// Set left and right click boundaries
			checkDragLeft = this.rowObjPos[0] + this.nodragLeft;
			checkDragRight = this.rowObjPos[0] + this.objRow.offsetWidth - this.nodragRight;

			// Is this a draggable area?
			if (this.grabPosX > checkDragLeft && this.grabPosX < checkDragRight)
			{
				this.currentCount = rowObj.rowIndex;
				this.currentID = rowObj.ID;

				// Create container element for floating the row
				this.divObj = document.createElement('div');

				// Some default styling for the container
				var style = {
					opacity: 0.8,
					filter: 'alpha(opacity=80)',
					left: this.rowObjPos[0]+'px',
					top: this.rowObjPos[1]+'px',
					position: 'absolute',
					height: this.objRow.offsetHeight+'px',
					width: this.objRow.offsetWidth+'px',
					cursor: 'pointer',
					borderTop: '1px solid #aaaaaa',
					borderBottom: '1px solid #aaaaaa',
					borderLeft: '1px solid #aaaaaa',
					borderRight: '1px solid #aaaaaa'
				};
				this.setStyle(this.divObj, style);

				// Apply a class name for external styling
				this.divObj.className = 'float';
				for(var i=0;i<this.divObj.getElementsByTagName("td").length;i++) {
					this.setStyle(this.divObj.getElementsByTagName("td")[i],{
						backgroundColor: '#e2edf5',
						opacity: 0.8,
						filter: 'alpha(opacity=80)'
					});
				}

				// Put the container on the page
				this.bodyObj.appendChild(this.divObj);

				// Get the grabbed position to maintain that position within the div
				this.grabPosY = this.grabPosY - this.rowObjPos[1];
				this.grabPosX = this.grabPosX - this.rowObjPos[0];

				// Get table body of the row
				tableBodyObj = rowObj.parentNode;

				// Clone the table
				tableObj = tableBodyObj.parentNode.cloneNode(false);
				tableObj.style.borderLeft = "0px solid #aaaaaa";
				tableObj.style.borderRight = "0px solid #aaaaaa";

				// Clone the table body
				tableBodyObj2 = tableBodyObj.cloneNode(false);


				if (this.showShadow)
				{
					// If the shadow needs to be displayed, clone the row and apply the shadow class
					this.shadowRow = this.objRow.cloneNode(true);
					this.shadowRow.className = 'shadow';
					for(var i=0;i<this.shadowRow.getElementsByTagName("td").length;i++) {
						this.setStyle(this.shadowRow.getElementsByTagName("td")[i],{
							opacity: 0.3,
							filter: 'alpha(opacity=30)',
							borderBottom: '1px solid #c7c7c7',
							backgroundColor: '#f5f5f5'
						});
					}
				}

				if (this.showBase)
				{
					// If the base needs to be displayed, clone the row and put it right back in the place its being removed from
					this.rowObjClone.className = 'base';
					this.rowObjClone = this.objRow.cloneNode(true);
					this.objRow.parentNode.insertBefore(this.rowObjClone, this.objRow);
				}

				// Remove the object
				this.objRow.parentNode.removeChild(this.objRow);

				// Place the object in the new table body
				tableBodyObj2.appendChild(this.objRow);

				// Place the table body in the table
				tableObj.appendChild(tableBodyObj2);

				// Get all the rows in the table
				this.objRows = tableBodyObj.getElementsByTagName('tr');

				// Place the cloned table in the floating div object
				this.divObj.appendChild(tableObj);

				this.moveBox(thisEvent, this.objRow, this.divObj, this.grabPosX, this.grabPosY);

				// Cache the bindings so they can be removed later
				this.moveBoxCache = this.moveBox.bindAsEventListener(this, this.objRow, this.divObj, this.grabPosX, this.grabPosY);
				this.dropBoxCache = this.objDrop.bindAsEventListener(this, this.objRow, this.divObj, this.rowObjClone, this.rowObjClone.parentNode, this.grabPosX, this.grabPosY);

				// Register the observations for events
				Event.observe(document, 'mousemove', this.moveBoxCache);
				Event.observe(document, 'mouseup', this.dropBoxCache);
			}
		}
	},

	/**
	 * Set object style from associative array of styles
	 *
	 * styleObject - object: object receiving the styling
	 * styles - array: array of setting -> value
	 *
	 * returns nothing
	 */
	setStyle: function(styleObject, styles)
	{
		for (i in styles) { styleObject.style[i] = styles[i]; }
	},

	/**
	 * Event-triggered function, redraws objects when the row is moved
	 *
	 * thisEvent - event object: event that triggered the drag
	 * divMove - div object: div that moved (usually only one unless this becomes super fancy)
	 * grabX - integer: X offset within the div
	 * grabY - integer: Y offset within the div
	 *
	 * returns nothing
	 */
	moveBox: function(eventObj, moveObject, divMove, grabX, grabY)
	{
		if (this.dragFlag)
		{
			// Calculate position of the event
			if(!eventObj)
				var eventObj=window.event;
			if (document.all){ this.objPosY = eventObj.clientY+this.scrollDoc(); }
				else { this.objPosY = eventObj.pageY; }
			if (document.all){ this.objPosX=eventObj.clientX; }
				else { this.objPosX = eventObj.pageX; }

			if (eventObj)
			{
				// Move (set top and left styles) the container div to the event position, offset by the grabbed offsets
				this.setStyle(divMove, {top:(this.objPosY-grabY)+'px',left:(this.objPosX-grabX)+'px'});

				// If the shadow is shown
				if (this.showShadow)
				{
					// Remove the shadow from the objRows to reduce clutter and weird calculations
					if (this.shadowSet)
					{
						if (this.shadowRow.parentNode)
						{
							this.shadowRow.parentNode.removeChild(this.shadowRow);
						}
					}

					// Added by lisa: find the first and last draggable row indexes
					var firstRowIndex = this.objRows.length - 1;
					var lastRowIndex = 0;
					for (var obj0=0; obj0<this.objRows.length; obj0++)
					{
						if(this.objRows[obj0].getElementsByTagName("div").length == 1 &&
						   this.objRows[obj0].getElementsByTagName("div")[0].id &&
						   obj0 > lastRowIndex)
							lastRowIndex = obj0;
						if(this.objRows[obj0].getElementsByTagName("div").length == 1 &&
						   this.objRows[obj0].getElementsByTagName("div")[0].id &&
						   obj0 < firstRowIndex)
							firstRowIndex = obj0;
					}

					// Loop from the end to the beginning of the rows to find where to locate the shadow
					for (var obj0 = this.objRows.length - 1; obj0 >= 0; obj0--)
					{
						// Added by lisa: only move row around if it's draggable;
						// like recursive categories have a bunch of rows, but only a few
						// of them are sortable at the time, depending on what's open
						if((this.objRows[obj0].getElementsByTagName("div").length == 1 &&
						    this.objRows[obj0].getElementsByTagName("div")[0].id) ||
						   (obj0+1 < this.objRows.length &&
						    this.objRows[obj0+1].getElementsByTagName("div").length == 1 &&
						    this.objRows[obj0+1].getElementsByTagName("div")[0].id))
						{
							// If the object position is greater than the position of the tested object
							if (this.objectPosition(this.objRows[obj0])[1] <= (this.objPosY-grabY))
							{
								// If this is the last [draggable] object in the table, use the appendChild function
								// otherwise insert the shadow before the row next in the table
								if (obj0+1 >= this.objRows.length)
									this.objRows[obj0].parentNode.appendChild(this.shadowRow);
								else
									this.objRows[obj0].parentNode.insertBefore(this.shadowRow, this.objRows[obj0+1]);

								this.shadowSet = true;
								break;
							}
							// If the object position is less than the position of the tested object...
							else if (this.objectPosition(this.objRows[firstRowIndex])[1] > (this.objPosY-grabY))
							{
								// ...then place the shadow in the very first row
								this.objRows[obj0].parentNode.insertBefore(this.shadowRow, this.objRows[firstRowIndex]);
								this.shadowSet = true;
								break;
							}
						}
					}
				}

				// Call the binded move function
				if (this.bindMoveFunction)
				{
					// The binded move call returns the beginning rowIndex, current rowIndex, and the id of the moved object
					this.bindMoveFunction(this.currentCount, moveObject.rowIndex, moveObject.id);
				}
			}
		}
	},

	/**
	 * 'Move' function triggered; gets the scroll distance
	 *
	 * no arguments
	 *
	 * returns integer: scrolled Y distance
	 */
	scrollDoc: function()
	{
		var scrollY;
		if (!document.body.scrollTop){ scrollY = document.documentElement.scrollTop; }
		else { scrollY = document.body.scrollTop; }
		return scrollY;
	},


	/**
	 * Event-triggered function, drops the object in the row specified
	 *
	 * eventObj - event object: event that triggered the drag
	 * dropObject - object: dropped Object
	 * divObj - object: dropped Div
	 * rowClone - object: cloned Row
	 * cloneParent - object: cloned Row parent
	 * grabX - integer: X offset within the div
	 * grabY - integer: Y offset within the div
	 *
	 * returns nothing
	 */
	objDrop: function(eventObj, dropObject, divObj, rowClone, cloneParent, grabX, grabY)
	{
		/*if (this.dragFlag)
		{*/
			// Reenable text selection
			document.onselectstart=function(){return true;}
			if (window.sidebar)
				document.onmousedown=function(){return true;}

			if (!dropObject){ return; }

			// Calculate the event position
			if(!eventObj)
				var eventObj=window.event;
			if (document.all){ this.objPosY = eventObj.clientY+this.scrollDoc(); }
				else { this.objPosY = eventObj.pageY; }
			if (document.all){ this.objPosX=eventObj.clientX; }
				else { this.objPosX = eventObj.pageX; }


			// If there was a clone being displayed, remove it
			if (this.showBase)
				cloneParent.removeChild(rowClone);

			// Added by lisa: find the first and last draggable row indexes
			var firstRowIndex = this.objRows.length - 1;
			var lastRowIndex = 0;
			for (var obj0=0; obj0<this.objRows.length; obj0++)
			{
				if(this.objRows[obj0].getElementsByTagName("div").length == 1 &&
				   this.objRows[obj0].getElementsByTagName("div")[0].id &&
				   obj0 > lastRowIndex)
					lastRowIndex = obj0;
				if(this.objRows[obj0].getElementsByTagName("div").length == 1 &&
				   this.objRows[obj0].getElementsByTagName("div")[0].id &&
				   obj0 < firstRowIndex)
					firstRowIndex = obj0;
			}

			// Loop through rows
			for (var obj0 = this.objRows.length - 1; obj0>=0; obj0--)
			{

				// Added by lisa: only move row around if it's draggable;
				// like recursive categories have a bunch of rows, but only a few
				// of them are sortable at the time, depending on what's open
				if((this.objRows[obj0].getElementsByTagName("div").length == 1 &&
				    this.objRows[obj0].getElementsByTagName("div")[0].id) ||
				   (obj0+1 < this.objRows.length &&
				    this.objRows[obj0+1].getElementsByTagName("div").length == 1 &&
				    this.objRows[obj0+1].getElementsByTagName("div")[0].id))
				{
					// If the object position is less than the event position
					if (this.objectPosition(this.objRows[obj0])[1] <= (this.objPosY-grabY))
					{
						// If this is the last row, use appendChild, otherwise insert before the row right after
						if (obj0+1 >= this.objRows.length)
							this.objRows[obj0].parentNode.appendChild(dropObject);
						else
							this.objRows[obj0].parentNode.insertBefore(dropObject, this.objRows[obj0+1]);
						break;
					}
					// If the object positon is greater
					else if (this.objectPosition(this.objRows[firstRowIndex])[1] > (this.objPosY-grabY))
					{
						// Insert in to the very first row
						this.objRows[obj0].parentNode.insertBefore(dropObject, this.objRows[firstRowIndex]);
						break;
					}
				}


			}

			// If there was a shadow, remove it
			if (this.shadowSet)
			{
				if (this.shadowRow.parentNode)
				{
					this.shadowRow.parentNode.removeChild(this.shadowRow);
					this.shadowSet = false;
				}
			}

			// Destroy the floated div
			divObj.parentNode.removeChild(divObj);

			// Reset everything
			this.doc = document;
			this.divObj = '';
			this.objRow = '';
			this.rowObjPos = '';
			this.bodyObj = document.getElementsByTagName('body')[0];
			this.objRows = '';
			this.objRRow = '';
			this.objPosY = '';
			this.objPosX = '';
			this.rowObjClone = '';
			this.shadowSet = false;

			// Stop observing the mouse events
			Event.stopObserving(document, 'mousemove', this.moveBoxCache);
			Event.stopObserving(document, 'mouseup', this.dropBoxCache);

			// Call the binded drop function
			if (this.bindDropFunction)
			{
				// The binded drop call returns the beginning rowIndex, end rowIndex, and the id of the dropped object
				this.bindDropFunction(this.currentCount, dropObject.rowIndex, dropObject.getElementsByTagName("div")[0].id);
			}
		/*}*/
	},

	/**
	 * Event-triggered function, not a bind in itself but called by the events binded to the drop
	 *
	 * definedFunction - function: function to be called when the event-triggered function finishes
	 *
	 * returns nothing
	 */
	bindDrop: function(definedFunction)
	{
		this.bindDropFunction = definedFunction;
	},

	/**
	 * Event-triggered function, not a bind in itself but called by the events binded to the move
	 *
	 * definedFunction - function: function to be called when the event-triggered function finishes
	 *
	 * returns nothing
	 */
	bindMove: function(definedFunction)
	{
		this.bindMoveFunction = definedFunction;
	},

	/**
	 * Enables the dragging functionality
	 *
	 * returns nothing
	 */
	enableDrag: function()
	{
		this.dragFlag = true;
	},

	/**
	 * Disables the dragging functionality
	 *
	 * returns nothing
	 */
	disableDrag: function()
	{
		this.dragFlag = false;
	}
};






