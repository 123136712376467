// Used on the email form to show additional attachments
function showAttachment(num) {
	var row = eval('document.getElementById("row_attachment' + num + '")');
	row.style.display = '';
}

// This is used on forms that have a lot of checkboxes, e.g. roles,
// address types, etc.
function toggleAll(isChecked,checkboxArray) {
	if(checkboxArray.length) {
		for(var i=0; i<checkboxArray.length; i++) {
			checkboxArray[i].checked = isChecked;
		}
	}
	else
		checkboxArray.checked = isChecked;
}

// This is used to turn help_text on/off to keep forms from being "cluttered"
var blnIsHelpTextOn = true;

// This is used for the payment type form, which has fields that are shown/hidden
// depending on which payment types have been selected
function showHidePaymentTypes(frm) {
	// Just turn everything off to begin with
	document.getElementById("creditcardtypeids").style.display = 'none';
	document.getElementById("gatewayid").style.display = 'none';
	document.getElementById("authorizelogin").style.display = 'none';
	document.getElementById("authorizetransactionkey").style.display = 'none';
	document.getElementById("authorizeversion").style.display = 'none';
	document.getElementById("authorizetestmode").style.display = 'none';
	document.getElementById("authorizeallowrecurring").style.display = 'none';
	document.getElementById("payfuseuserid").style.display = 'none';
	document.getElementById("payfusepassword").style.display = 'none';
	document.getElementById("payfusealias").style.display = 'none';
	document.getElementById("payfusetestmode").style.display = 'none';
	document.getElementById("verisignvendor").style.display = 'none';
	document.getElementById("verisignpartner").style.display = 'none';
	document.getElementById("verisignuser").style.display = 'none';
	document.getElementById("verisignpassword").style.display = 'none';
	document.getElementById("verisigntestmode").style.display = 'none';
	document.getElementById("IsAuthorizeCaptureSeparated").style.display = 'none';

	document.getElementById("dwollaaccountid").style.display = 'none';
	if (document.getElementById("dwollaapplicationkey")) {
		document.getElementById("dwollaapplicationkey").style.display = 'none';
	}
	if (document.getElementById("dwollaapplicationsecret")) {
		document.getElementById("dwollaapplicationsecret").style.display = 'none';
	}
	document.getElementById("dwollatestmode").style.display = 'none';

	for(var i=0; i<frm.paymenttypeids.length; i++) {
		// Credit card info
		if(frm.paymenttypeids[i].value == 1 && frm.paymenttypeids[i].checked) {
			document.getElementById("creditcardtypeids").style.display = '';
			document.getElementById("gatewayid").style.display = '';
			document.getElementById("IsAuthorizeCaptureSeparated").style.display = '';
			// Payment Gateway
			if(frm.gatewayid.options[frm.gatewayid.selectedIndex].value == 3) {
				document.getElementById("authorizelogin").style.display = '';
				document.getElementById("authorizetransactionkey").style.display = '';
				document.getElementById("authorizeversion").style.display = '';
				document.getElementById("authorizetestmode").style.display = '';
				document.getElementById("authorizeallowrecurring").style.display = '';
			}
			else if(frm.gatewayid.options[frm.gatewayid.selectedIndex].value == 4) {
				document.getElementById("verisignvendor").style.display = '';
				document.getElementById("verisignpartner").style.display = '';
				document.getElementById("verisignuser").style.display = '';
				document.getElementById("verisignpassword").style.display = '';
				document.getElementById("verisigntestmode").style.display = '';
			}
			else if(frm.gatewayid.options[frm.gatewayid.selectedIndex].value == 7) {
				document.getElementById("payfuseuserid").style.display = '';
				document.getElementById("payfusepassword").style.display = '';
				document.getElementById("payfusealias").style.display = '';
				document.getElementById("payfusetestmode").style.display = '';
			}
		}
		// Paypal info
		else if(frm.paymenttypeids[i].value == 3 && frm.paymenttypeids[i].checked)
			document.getElementById("paypalmerchantid").style.display = '';
		else if(frm.paymenttypeids[i].value == 3 && !frm.paymenttypeids[i].checked)
			document.getElementById("paypalmerchantid").style.display = 'none';
		// Dwolla info
		else if(frm.paymenttypeids[i].value == 10 && frm.paymenttypeids[i].checked) {
			document.getElementById("dwollaaccountid").style.display = '';
			if (document.getElementById("dwollaapplicationkey")) {
				document.getElementById("dwollaapplicationkey").style.display = '';
			}
			if (document.getElementById("dwollaapplicationsecret")) {
				document.getElementById("dwollaapplicationsecret").style.display = '';
			}
			document.getElementById("dwollatestmode").style.display = '';
		} else if(frm.paymenttypeids[i].value == 10 && !frm.paymenttypeids[i].checked) {
			document.getElementById("dwollaaccountid").style.display = 'none';
			if (document.getElementById("dwollaapplicationkey")) {
				document.getElementById("dwollaapplicationkey").style.display = 'none';
			}
			if (document.getElementById("dwollaapplicationsecret")) {
				document.getElementById("dwollaapplicationsecret").style.display = 'none';
			}
			document.getElementById("dwollatestmode").style.display = 'none';
		}
	}
}

// This is used on the general member form, where name fields depend on whether or not
// the member is a company
function changeUserNameDisplay(blnIsCompany) {
	if(blnIsCompany == '') {
		document.getElementById('row_prefix').style.display = 'none';
		document.getElementById('row_firstname').style.display = 'none';
		document.getElementById('row_lastname').style.display = 'none';
		document.getElementById('row_companyname').style.display = 'none';
		document.getElementById('row_website').style.display = 'none';
		if (document.getElementById('row_contactname')){
			document.getElementById('row_contactname').style.display = 'none';
		}
		if (document.getElementById('row_addacontact')){
			document.getElementById('row_addacontact').style.display = 'none';
		}
	}
	else if(blnIsCompany == '1') {
		document.getElementById('row_prefix').style.display = 'none';
		document.getElementById('row_firstname').style.display = 'none';
		document.getElementById('row_lastname').style.display = 'none';
		document.getElementById('row_companyname').style.display = '';
		document.getElementById('row_website').style.display = '';
		if (document.getElementById('row_contactname')){
			document.getElementById('row_contactname').style.display = '';
		}
		if (document.getElementById('row_addacontact')){
			document.getElementById('row_addacontact').style.display = '';
		}
	}
	else if(blnIsCompany == '0') {
		document.getElementById('row_prefix').style.display = '';
		document.getElementById('row_firstname').style.display = '';
		document.getElementById('row_lastname').style.display = '';
		document.getElementById('row_companyname').style.display = 'none';
		document.getElementById('row_website').style.display = 'none';
		if (document.getElementById('row_contactname')){
			document.getElementById('row_contactname').style.display = 'none';
		}
		if (document.getElementById('row_addacontact')){
			document.getElementById('row_addacontact').style.display = 'none';
		}
	}
}

// This is used on the attendee form, where name fields depend on whether or not
// the attendee is a member
function changeAttendeeDisplay(blnIsMember) {
	if(blnIsMember == '') {
		document.getElementById('row_prefix').style.display = 'none';
		document.getElementById('row_firstname').style.display = 'none';
		document.getElementById('row_lastname').style.display = 'none';
		document.getElementById('row_companyname').style.display = 'none';
		document.getElementById('row_jobtitle').style.display = 'none';
		document.getElementById('row_email').style.display = 'none';
		document.getElementById('row_isreceivingemail').style.display = 'none';
		document.getElementById('row_user_id').style.display = 'none';
		document.getElementById('row_notes').style.display = 'none';
	}
	else if(blnIsMember == '1') {
		document.getElementById('row_prefix').style.display = 'none';
		document.getElementById('row_firstname').style.display = 'none';
		document.getElementById('row_lastname').style.display = 'none';
		document.getElementById('row_companyname').style.display = 'none';
		document.getElementById('row_jobtitle').style.display = 'none';
		document.getElementById('row_email').style.display = 'none';
		document.getElementById('row_isreceivingemail').style.display = '';
		document.getElementById('row_user_id').style.display = '';
		document.getElementById('row_notes').style.display = '';
	}
	else if(blnIsMember == '0') {
		document.getElementById('row_prefix').style.display = '';
		document.getElementById('row_firstname').style.display = '';
		document.getElementById('row_lastname').style.display = '';
		document.getElementById('row_companyname').style.display = '';
		document.getElementById('row_jobtitle').style.display = '';
		document.getElementById('row_email').style.display = '';
		document.getElementById('row_isreceivingemail').style.display = '';
		document.getElementById('row_user_id').style.display = 'none';
		document.getElementById('row_notes').style.display = '';
	}
}

// This is used on the forms with payment intervals; extra fields only show up when "every:"
// is selected
function changeFrequencyDisplay(blnIsRecurring) {
	if(blnIsRecurring == '') {
		document.getElementById('everynumber').style.display = 'none';
		document.getElementById('everyunit').style.display = 'none';
	}
	else if(blnIsRecurring == '1') {
		document.getElementById('everynumber').style.display = '';
		document.getElementById('everyunit').style.display = '';
	}
	else if(blnIsRecurring == '0') {
		document.getElementById('everynumber').style.display = 'none';
		document.getElementById('everyunit').style.display = 'none';
	}
}

// The following are used on forms that have pop-up user searches
function openUserSearch(userTypeID) {
	var search_window = window.open('/users/searchpopup?clear=true&userTypeID=' + userTypeID,'search_window','width=1020,height=200,scrollbars=yes,resizable=yes,status=no');
	search_window.focus();
}
function makeSelection(user) {
	var frm = window.opener.top.document.getElementById("mainform");

	// If "ContactForUserID" is defined, use that over "UserID"
	var customerDD;
	if(frm.contactforuserid) {
		customerDD = frm.contactforuserid.options[1];
	}
	else {
		customerDD = frm.userid.options[1];
	}
	customerDD.value = user.split('$')[0];
	customerDD.text = user.split('$')[1];
	customerDD.selected = true;

	// If a billing address exists and all the fields on the form are
	// blank, auto-fill the billing address with billing/primary address
	// information of the user just selected
	if(frm.bfirstname && frm.baddress1 && frm.bcity/* &&
	   frm.bfirstname.value == '' && frm.baddress1.value == '' && frm.bcity.value == ''*/) {
		frm.bprefix.value = user.split('$')[14];
		frm.bfirstname.value = user.split('$')[2];
		frm.bmiddleinitial.value = user.split('$')[15];
		frm.blastname.value = user.split('$')[3];
		frm.bcompanyname.value = user.split('$')[4];
		frm.baddress1.value = user.split('$')[5];
		frm.baddress2.value = user.split('$')[6];
		frm.bcity.value = user.split('$')[7];
		frm.bstatecode.value = user.split('$')[8];
		frm.bpostalcode.value = user.split('$')[9];
		for(var i=0; i<frm.bcountrycode.options.length; i++) {
			if((frm.bcountrycode.options[i].value == user.split('$')[10]) ||
			   (frm.bcountrycode.options[i].value == 'US' && user.split('$')[10] == ''))
				frm.bcountrycode.selectedIndex = i;
		}
		frm.bphone.value = user.split('$')[11];
		frm.bextension.value = user.split('$')[12];
		frm.bemail.value = user.split('$')[13];
	}

	// If a billing address exists and all the fields on the form are
	// blank, auto-fill the invoice address with billing/primary address
	// information of the user just selected
	if(frm.bfirstname && frm.baddress1 && frm.bcity/* &&
	   frm.bfirstname.value == '' && frm.baddress1.value == '' && frm.bcity.value == ''*/) {
		frm.iprefix.value = user.split('$')[14];
		frm.ifirstname.value = user.split('$')[2];
		frm.imiddleinitial.value = user.split('$')[15];
		frm.ilastname.value = user.split('$')[3];
		frm.icompanyname.value = user.split('$')[4];
		frm.iaddress1.value = user.split('$')[5];
		frm.iaddress2.value = user.split('$')[6];
		frm.icity.value = user.split('$')[7];
		frm.istatecode.value = user.split('$')[8];
		frm.ipostalcode.value = user.split('$')[9];
		for(var i=0; i<frm.icountrycode.options.length; i++) {
			if((frm.icountrycode.options[i].value == user.split('$')[10]) ||
			   (frm.icountrycode.options[i].value == 'US' && user.split('$')[10] == ''))
				frm.icountrycode.selectedIndex = i;
		}
		frm.iphone.value = user.split('$')[11];
		frm.iextension.value = user.split('$')[12];
		frm.iemail.value = user.split('$')[13];
	}
	window.close();
}

// IE is so, so lame
function setElementAttribute(obj,attribute,value) {
	for(var a = 0; a < obj.attributes.length; a++) {
		if(obj.attributes[a].name.toUpperCase() == attribute)
			obj.attributes[a].value = value;
	}
	obj.setAttribute(attribute,value);
}

// This is used on the payment billing form, where payment fields depend on what kind of payment it is
function changePaymentTypeDisplay(intPaymentTypeID) {
	if(intPaymentTypeID == '') {
		document.getElementById('row_credit_card_type_id').style.display = 'none';
		document.getElementById('row_cc_number').style.display = 'none';
		document.getElementById('row_cc_code').style.display = 'none';
		document.getElementById('row_cc_expirationdate').style.display = 'none';
		document.getElementById('row_check_number').style.display = 'none';
		//document.getElementById('row_transaction_number').style.display = 'none';
	}
	else if(intPaymentTypeID == '1') {
		document.getElementById('row_credit_card_type_id').style.display = '';
		document.getElementById('row_cc_number').style.display = '';
		document.getElementById('row_cc_code').style.display = '';
		document.getElementById('row_cc_expirationdate').style.display = '';
		document.getElementById('row_check_number').style.display = 'none';
		//document.getElementById('row_transaction_number').style.display = 'none';
		//document.getElementById('row_transaction_number').style.display = '';
	}
	else if(intPaymentTypeID == '2') {
		document.getElementById('row_credit_card_type_id').style.display = 'none';
		document.getElementById('row_cc_number').style.display = 'none';
		document.getElementById('row_cc_code').style.display = 'none';
		document.getElementById('row_cc_expirationdate').style.display = 'none';
		document.getElementById('row_check_number').style.display = '';
		//document.getElementById('row_transaction_number').style.display = '';
		//document.getElementById('row_transaction_number').style.display = 'none';
	}
	else {
		document.getElementById('row_credit_card_type_id').style.display = 'none';
		document.getElementById('row_cc_number').style.display = 'none';
		document.getElementById('row_cc_code').style.display = 'none';
		document.getElementById('row_cc_expirationdate').style.display = 'none';
		document.getElementById('row_check_number').style.display = 'none';
		//document.getElementById('row_transaction_number').style.display = '';
		//document.getElementById('row_transaction_number').style.display = 'none';
	}
}

// This is used on the payment billing form, where invoice fields become available when an
// agency has been selected
function changeInvoicedToDisplay(agencyID) {
	if(agencyID == '') {
		document.getElementById('row_invoiced_to').style.display = 'none';
		document.getElementById('row_invoiced_to_prefix').style.display = 'none';
		document.getElementById('row_invoiced_to_first_name').style.display = 'none';
		document.getElementById('row_invoiced_to_last_name').style.display = 'none';
		document.getElementById('row_invoiced_to_company_name').style.display = 'none';
		document.getElementById('row_invoiced_to_address1').style.display = 'none';
		document.getElementById('row_invoiced_to_address2').style.display = 'none';
		document.getElementById('row_invoiced_to_city').style.display = 'none';
		document.getElementById('row_invoiced_to_state_code').style.display = 'none';
		document.getElementById('row_invoiced_to_postal_code').style.display = 'none';
		document.getElementById('row_invoiced_to_country_code').style.display = 'none';
		document.getElementById('row_invoiced_to_phone').style.display = 'none';
		document.getElementById('row_invoiced_to_email').style.display = 'none';
	}
	else {
		document.getElementById('row_invoiced_to').style.display = '';
		document.getElementById('row_invoiced_to_prefix').style.display = '';
		document.getElementById('row_invoiced_to_first_name').style.display = '';
		document.getElementById('row_invoiced_to_last_name').style.display = '';
		document.getElementById('row_invoiced_to_company_name').style.display = '';
		document.getElementById('row_invoiced_to_address1').style.display = '';
		document.getElementById('row_invoiced_to_address2').style.display = '';
		document.getElementById('row_invoiced_to_city').style.display = '';
		document.getElementById('row_invoiced_to_state_code').style.display = '';
		document.getElementById('row_invoiced_to_postal_code').style.display = '';
		document.getElementById('row_invoiced_to_country_code').style.display = '';
		document.getElementById('row_invoiced_to_phone').style.display = '';
		document.getElementById('row_invoiced_to_email').style.display = '';
	}
}

// This is used on the organization form, where Email Header Image and Email Witdh fields
// visibility is updated based on a flag
function changeCustomizedEmailTemplateDisplay(isAllowCustomEmailHeaders) {
	if(isAllowCustomEmailHeaders == '1') {
		document.getElementById('row_custom_email_header').style.display = '';
		document.getElementById('row_custom_email_width').style.display = '';
	} else {
		document.getElementById('row_custom_email_header').style.display = 'none';
		document.getElementById('row_custom_email_width').style.display = 'none';
	}
}

// This is used on the organization form, where User Event Notification Email field
// visibility is updated based on a flag
function changeAllowUserEvents(isAllowUserEvents) {
	if(isAllowUserEvents == '1') {
		document.getElementById('row_user_event_notification_email').style.display = '';
	} else {
		document.getElementById('row_user_event_notification_email').style.display = 'none';
	}
}

// Disable any submit buttons on a form
function disableSubmit(frm) {
	for (var i=0; i<frm.elements.length; i++) {
		if (frm.elements[i].type == "submit") {
			frm.elements[i].disabled = true;
		}
	}
}

// Used on export forms, since some formats need a new window
function exportData(action, format) {
	var $frm = $("#mainform");
	// If it's a newsletter or renewal form, it will need to open in a new window
	if (action == 'newsletter' || action == 'member_renewal' || action == 'pdf_items') {
		$frm.attr("target", "_blank");
	} else {
		$frm.attr("target", "");
	}

	// If it's avery5160 or pdf anything, it uses a cf tag so needs to go through fusebox
	if (action == 'avery5160') {
		$frm.attr("action", $frm.attr('action') + "/exportAvery5160");
	} else if (action == 'pdf_items') {
		$frm.attr("action", $frm.attr('action') + "/exportPDF");
	} else if (action == 'csv') {
		if (format != undefined) {
			$("#exportformat").val(format);
		}
		$frm.attr("action", $frm.attr('action') + "/exportCsv");
	} else {
		console.error("DEFINE ACTION HERE. DON'T DO MAGIC UNLESS NECESSARY");
		return;
	}

	$frm.submit();
}

function toggleHelpText() {
	// Update spans
	$("#mainform span.help_text").css("display", (blnIsHelpTextOn) ? "block" : "none")

	// Toggle
	if(blnIsHelpTextOn) {
		if(document.getElementById("hints_text_span")) {
			document.getElementById("hints_text_span").innerHTML = "Hide hints";
		}
		blnIsHelpTextOn = false;
	}
	else {
		if(document.getElementById("hints_text_span")) {
			document.getElementById("hints_text_span").innerHTML = "Show hints";
		}
		blnIsHelpTextOn = true;
	}
}



$(function() {
	$(".loading-spinner").spin({
		color:'#000',
		lines: 12
	});


	// DataTables Defaults
	$.extend($.fn.dataTable.defaults, {
		"ajax": {
			'data': function(data) {
				return {
					'draw': data.draw,
					'columns': JSON.stringify(data.columns),
					'order': data.order,
					'start': data.start,
					'length': data.length,
					'search': JSON.stringify(data.search),
					'organizationid': searchArgs.organizationid,
					'startdate': searchArgs.startdate,
					'enddate': searchArgs.enddate
				};
			}
		},
		"dom": '<<"#filter-container"lpi><t>>',
		"language": {
			"lengthMenu": "_MENU_ per page",
			"info": "_TOTAL_ entries",
			"infoEmpty": "0 entries"
		},
        "processing": true,
        "serverSide": true,
		"autoWidth": false,
		"deferRender": true,
		"searching": false,
		"order": [],
	});
});
